
import { useNavigate } from "react-router-dom";

import eth from './assets/ethereum.svg'
import polygon from './assets/polygon.svg'
import arbitrum from './assets/arbitrum.svg'
import aptos from './assets/aptos.svg'
import bnb from './assets/bnb.svg'
import ripple_xrp from './assets/ripple_xrp.svg'
import op from './assets/op.svg'
import zksync_era from './assets/zksync_era.svg'
import polygon_zkevm from './assets/polygon_zkevm.svg'
import solona from './assets/solona.svg'
import sui from './assets/Sui.svg'

const CHAINS = [
  {
    name: 'Ethereum',
    value: 'ethereum',
    image: eth,
    implemented: true,
  },
  {
    name: 'Sui',
    value: 'sui',
    image: sui,
    implemented: true,
  },
  {
    name: 'BNB Chain',
    value: 'bnb',
    image: bnb
  },
  {
    name: 'Polygon',
    value: 'polygon',
    image: polygon
  },
  {
    name: 'Arbitrum',
    value: 'arbitrum',
    image: arbitrum
  },
  {
    name: 'Solana',
    value: 'solana',
    image: solona
  },
  {
    name: 'Aptos',
    value: 'aptos',
    image: aptos
  },
  {
    name: 'Polygon zkEVM',
    value: 'polygon_zkevm',
    image: polygon_zkevm
  },
  {
    name: 'Ripple XRP',
    value: 'ripple_xrp',
    image: ripple_xrp
  },
  {
    name: 'OP',
    value: 'OP',
    image: op
  },
  {
    name: 'zkSync Era',
    value: 'zksync_era',
    image: zksync_era
  },

]

export default function RealtimeAPI() {
  const navigate = useNavigate();


  return <div className="min-h-[calc(100vh-180px)]">
    <div className="px-3 pb-6 flex justify-left flex-wrap">
      {
        CHAINS.map(chain => {


          return <div
            onClick={() => {
              if (chain.implemented) navigate(`/my-workspace/realtime-api/${chain.value}`)
            }}
            className="hover:cursor-pointer justify-center flex bg-[#fafbfc] items-center flex-col w-[23%] h-[180px] mx-[1%] mt-6 relative">
            <div className="absolute top-2 right-2 text-[0px]">
              {!chain.implemented && <div className="rounded-sm	border-[0] text-[10px] badge badge-secondary font-semibold bg-[#e9e9fa] text-[#807af5] h-[1rem] px-1">
                Coming Soon
              </div>
              }
            </div>
            <img src={chain.image} width={66} />
            <span className="my-2 font-semibold text-lg">
              {chain.name}
            </span>
            <span className="text-xs opacity-[0.5]">
              Mainnet
            </span>
          </div>
        })
      }

    </div>

  </div>;
}