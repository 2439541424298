import { Avatar, Button, Card, Col, List, Row, Select, Space, Tag } from 'antd';
import {
  FolderOutlined,
  FileOutlined,
  SafetyOutlined,
  EnterOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  repoDetail,
  repoDetailDTO,
} from '../../../modules/apis/neo/repoDetail';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigator } from '../../../useNavigator';
import moment from 'moment';
import formatBytes from '../../../../modules/connectors/utils/formatBytes';
import { getOrgInfoByName } from '../../../modules/apis/options';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import {
  assetDetail,
  assetDetailDTO,
} from '../../../modules/apis/neo/assetDetail';

export default function ModelDetail() {
  const navigator = useNavigator();
  const queryParams = useParams();
  const [query, setQuery] = useState({
    tabKey: 'overview',
  } as Partial<repoDetailDTO.Req> & { tabKey?: string; parent_folder?: string });
  const [state, setState] = useState({} as repoDetailDTO.RepoRes);
  const [asset, setAsset] = useState({} as assetDetailDTO.Asset);

  useEffect(() => {
    if (queryParams.id) {
      assetDetail({
        id: queryParams.id,
      }).then((res) => {
        setAsset(res.data ?? ({} as assetDetailDTO.Asset));
      });
    }
  }, [queryParams.id]);

  useEffect(() => {
    if (queryParams.id) {
      repoDetail({
        ...query,
        asset_id: queryParams.id,
      }).then((res) => {
        setState(res.data ?? ({} as repoDetailDTO.RepoRes));
      });
    }
  }, [query]);

  const contentTabs = useMemo(() => {
    return {
      overview: {
        key: 'overview',
        tab: 'Overview',
        content: (
          <Row gutter={40}>
            <Col span={20}>
              <Space className='flex justify-between'>
                <Space>
                  <Select
                    defaultValue='xxx'
                    options={[
                      {
                        value: 'xxx',
                        label: 'Main',
                      },
                    ]}
                  />
                  <span>1 Branch</span>
                </Space>
                <Space>
                  <span>1 contributor</span>
                  <Select
                    defaultValue='xxx'
                    options={[
                      {
                        value: 'xxx',
                        label: 'History: 10 commits',
                      },
                    ]}
                  />
                </Space>
              </Space>
              <List
                className='mt-4'
                header={
                  <Space>
                    {query.folder_path && (
                      <Button
                        size='small'
                        type='text'
                        onClick={() => {
                          setQuery({
                            ...query,
                            parent_folder: '',
                            folder_path: query.parent_folder,
                          });
                        }}
                        icon={<EnterOutlined rev={undefined} />}
                      >
                        ..
                      </Button>
                    )}
                    <span>
                      <Avatar
                        src={state.repository?.avatar_url}
                        className='w-5 h-5'
                      />
                      {state.repository?.created_on}
                    </span>
                    <span className='text-gray-400'>last commit</span>
                    <span className='text-gray-400'>Update README.md</span>
                  </Space>
                }
                bordered
                dataSource={state.contents ?? []}
                renderItem={(item) => (
                  <List.Item className='text-gray-600'>
                    <Space className='w-[50%] justify-between'>
                      {item.type === 'dir' ? (
                        <div>
                          <FolderOutlined rev={undefined} />{' '}
                          <a
                            className='text-gray-900'
                            onClick={() => {
                              setQuery({
                                ...query,
                                parent_folder: query.folder_path,
                                folder_path: item.path,
                              });
                            }}
                          >
                            {item.name}
                          </a>{' '}
                        </div>
                      ) : (
                        <div>
                          <FileOutlined rev={undefined} />{' '}
                          <a
                            className='text-gray-900'
                            onClick={() => {
                              const href = `${process.env.REACT_APP_ZETTAAI_GITEA}/${state.repository?.full_name}/src/branch/main/${item.path}`;
                              Object.assign(document.createElement('a'), {
                                target: '_blank',
                                rel: 'noopener noreferrer',
                                href,
                              }).click();
                            }}
                          >
                            {item.name}
                          </a>{' '}
                          <SafetyOutlined rev={undefined} />
                        </div>
                      )}
                      <div className='pr-4 text-gray-400'>
                        {formatBytes(item.size)}
                      </div>
                    </Space>
                    <Space className='w-[50%] justify-between text-gray-400'>
                      <div>{item.latest_commit.message}</div>
                      <div>
                        {moment(item.latest_commit.created_at || 0).fromNow()}
                      </div>
                    </Space>
                  </List.Item>
                )}
              />
              <MarkdownPreview
                wrapperElement={{
                  'data-color-mode': 'light',
                }}
                // className={styles.setupMarkdown}
                className='markdown-body text-[#999ca0] my-[20px] text-[14px]'
                source={state.repository?.description}
              />
            </Col>
            <Col span={4} className='font-bold' style={{ lineHeight: '28px' }}>
              <h5 className='text-gray-400 my-2'>Stats dashboard</h5>
              <Row justify='space-between'>
                <Col>Viewers</Col>
                <Col>180</Col>
              </Row>
              <Row justify='space-between'>
                <Col>Inference calls</Col>
                <Col>22</Col>
              </Row>
              <Row justify='space-between'>
                <Col>Model hosts</Col>
                <Col>18</Col>
              </Row>
              <Row justify='space-between'>
                <Col>Subscribers</Col>
                <Col>56</Col>
              </Row>
              <h5 className='text-gray-400 my-2'>Owner Info</h5>
              <Row justify='space-between'>
                <Col>Owner 01</Col>
                <Col>80%</Col>
              </Row>
              <Row justify='space-between'>
                <Col>Owner 02</Col>
                <Col>20%</Col>
              </Row>
              <h5 className='text-gray-400 my-2'>Pricing</h5>
              <Row justify='space-between'>
                <Col>Modal User</Col>
                <Col>20 ZB/m token</Col>
              </Row>
              <Row justify='space-between'>
                <Col>Modal hosting</Col>
                <Col>20%</Col>
              </Row>
            </Col>
          </Row>
        ),
      },
    };
  }, [query, state]);

  const contentTab = contentTabs[query.tabKey!] || contentTabs.overview;
  const orgInfo = state.repository && getOrgInfoByName(state.repository?.name);
  const link = `${process.env.REACT_APP_ZETTAAI_GITEA}/${state.repository?.full_name}`;
  return (
    <main>
      <Card
        style={{ boxShadow: 'none' }}
        headStyle={{ borderBottom: '0 none' }}
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
        title={
          <Space>
            <Button type='text' size='small' onClick={() => navigator.go(-1)}>
              ← Back
            </Button>
            <h3 className='font-bold'>{state.repository?.full_name}</h3>
            {orgInfo && (
              <Space className='ml-4'>
                <Avatar src={orgInfo[1]} className='w-4 h-4' />
                {orgInfo[0]}
              </Space>
            )}
          </Space>
        }
      >
        <Space>
          <Space>
            <span>Modalities: </span>
            <Tag>{asset.modality}</Tag>
          </Space>
          <Space>
            <span>License:</span>
            <Tag>{asset.license}</Tag>
          </Space>
          <Space>
            <span>Link:</span>
            <Tag>
              <a href={link} target='_blank' rel='noreferrer'>
                {link}
              </a>
              <CopyToClipboard text={link}>
                <CopyOutlined
                  className='ml-2'
                  rev={undefined}
                  onClick={() => {
                    toast.success('Copied to clipboard');
                  }}
                />
              </CopyToClipboard>
            </Tag>
          </Space>
          <Space>
            <span>Lineage:</span>
            <Tag>
              {asset.lineage}
              <InfoCircleOutlined className='ml-2' rev={undefined} />
            </Tag>
          </Space>
        </Space>
      </Card>
      <Card
        style={{ width: '100%', boxShadow: 'none' }}
        bordered={false}
        tabList={Object.values(contentTabs)}
        activeTabKey={query.tabKey}
        onTabChange={(tabKey) => {
          setQuery({ ...query, tabKey });
        }}
      >
        {contentTab.content}
      </Card>
    </main>
  );
}
