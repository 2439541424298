import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { CodepenCircleOutlined } from '@ant-design/icons';
import { RootLayout } from './layout';
import { OpenAuth } from './pages/auth';
import ModelList from './pages/marketplace/model-list';
import ModelDetail from './pages/marketplace/model-detail';
import DatasetList from './pages/marketplace/dataset-list';
import DatasetDetail from './pages/marketplace/dataset-detail';
import { Result } from 'antd';
import ModelCreate from './pages/marketplace/model-create';
import DatasetCreate from './pages/marketplace/dataset-create';
import AuthProfile from './pages/auth/profile';
import AuthTokens from './pages/auth/tokens';
import AuthApikey from './pages/auth/apikey';
import AuthLayout from './pages/auth/layout';

const baseURL = process.env.REACT_APP_ZETTAAI_BACKEND_API;

function AccessPage() {
  const [searchParams] = useSearchParams();

  const inOpener = searchParams.has('code') && window.opener !== window;

  useEffect(() => {
    if (inOpener) {
      window.name = `?code=${searchParams.get('code')}&state=${searchParams.get(
        'state'
      )}`;
      setTimeout(() => {
        window.close();
      }, 1000);
    }
  }, []);

  return (
    <Result
      status='success'
      title='Successfully OAuth Login!'
      subTitle={`authorization code: ${searchParams.get(
        'code'
      )}, authorize github takes several seconds, please wait.`}
    />
  );
}

const mainMenus = [
  {
    key: 'marketplace',
    icon: <CodepenCircleOutlined className='ml-3' rev={undefined} />,
    label: 'Marketplace',
    children: [
      {
        key: '/aiweb/models',
        icon: <div className='ml-3' />,
        label: 'Model',
      },
      {
        key: '/aiweb/datasets',
        icon: <div className='ml-3' />,
        label: 'Data',
      },
    ],
  },
];
const authMenus = [
  {
    label: 'Profile',
    key: '/aiweb/profile',
  },
  {
    label: 'Apikey',
    key: '/aiweb/api-keys',
  },
  {
    label: 'Tokens',
    key: '/aiweb/tokens',
  },
];

export const AiWebRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    toast.dismiss();
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<Navigate to='/aiweb/models' />} />
      <Route path='/access' element={<AccessPage />} />
      <Route
        path='/login'
        element={
          <OpenAuth type='signin' authUrl={`${baseURL}/signin/github`} />
        }
      />
      <Route
        path='/signup'
        element={
          <OpenAuth type='signup' authUrl={`${baseURL}/signin/github`} />
        }
      />
      <Route path='/' element={<AuthLayout menus={authMenus} />}>
        <Route path='/profile' element={<AuthProfile />} />
        <Route path='/tokens' element={<AuthTokens />} />
        <Route path='/api-keys' element={<AuthApikey />} />
      </Route>

      <Route
        path='/'
        element={
          <RootLayout menus={mainMenus} defaultOpenKeys={[mainMenus[0].key]} />
        }
      >
        <Route path='/models' element={<ModelList />} />
        <Route path='/models/:id' element={<ModelDetail />} />
        <Route path='/model/create' element={<ModelCreate />} />
        <Route path='/datasets' element={<DatasetList />} />
        <Route path='/datasets/:id' element={<DatasetDetail />} />
        <Route path='/dataset/create' element={<DatasetCreate />} />
        <Route path='*' element={<Navigate to='/aiweb/models' />} />
      </Route>
      <Route path='*' element={<Navigate to='/aiweb/models' />} />
    </Routes>
  );
};
