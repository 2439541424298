import classNames from "classnames";
import chainIcon from './chain.svg'

import { useState } from "react";
import { PoProject } from "../../api/__gen__/data-contracts";
import { ExploreDataEnum, useExploreData } from "../useExploreData";
import LoadingIndicator from "../../components/LoadingIndicator";
import OverlapImages from "../components/OverlapImages";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
// import Icon from "v2/components/Icon";


export const LABELS = [{
  name: 'Core',
  id: 'core',
  icon: 'icon-core'
}, {
  name: 'Metadata',
  id: 'metadata',
  icon: 'icon-metadata'
}, {
  name: 'Abstraction',
  id: 'abstraction',
  icon: 'icon-abstraction'
}, {
  name: 'Price',
  id: 'price',
  icon: 'icon-price'
}, {
  name: 'Token',
  id: 'token',
  icon: 'icon-token'
}, {
  name: 'Transfer',
  id: 'transfer',
  icon: 'icon-transfer'
}, {
  name: 'NFT',
  id: 'nft',
  icon: 'icon-nft'
}, {
  name: 'DeFi',
  id: 'defi',
  icon: 'icon-defi'
}, {
  name: 'ENS',
  id: 'ens',
  icon: 'icon-ens'
}]
export const CHAINS = [
  {
    name: "Ethereum",
    id: "Ethereum"
  },
  {
    name: 'Polygon',
    id: 'Polygon'
  },
  // {
  //   name: 'Bitcoin',
  //   id: 'Bitcoin'
  // },
  {
    name: 'Base',
    id: 'Base'
  },

  {
    name: 'Solana',
    id: 'Solana'
  },
  {
    name: 'Sui',
    id: 'Sui'
  },
  {
    name: 'zkSync',
    id: 'zkSync'
  },
  {
    name: 'Polygon zkEVM',
    id: 'Polygon zkEVM'
  },
  {
    name: "Arbitrum",
    id: "Arbitrum"
  },
  {
    name: "Beacon",
    id: "Beacon"
  }
]


interface TagProps {
  name: string;
  id: string;
  image?: string;
  className?: string;
  isActive?: boolean;
  activeClassName?: string;
  onClick?: (id: string) => void;
  activeName?: string;
}
export const Tag = ({
  name,
  activeName,
  id,
  image,
  className,
  activeClassName,
  isActive,
  onClick
}: TagProps) => {

  return <div className={classNames(
    'flex h-[24px] px-[8px] py-[4px] rounded-full items-center gap-[5px] cursor-pointer w-auto',
    className && {
      [className]: !isActive
    },
    activeClassName && {
      [activeClassName]: isActive
    }
  )}
    onClick={() => { onClick?.(id) }}
  >
    {!image ? <Icon iconName={isActive ? (activeName || name) : name} className="w-[14px] h-[14px]" /> : <Icon iconType="png" iconName={isActive ? (`${image}-active` || image) : image} className="w-[14px] h-[14px]" />}
    <div className="text-[12px] leading-[12px]">
      {name}
    </div>
  </div>
}

interface TagProps {
  name: string;
  id: string;
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: string;
}
interface ChainTagsProps {
  chains: TagProps[];
  selectedChains: string[];
  onSelectChain: (chain: string) => void;
}

const ChainTags = ({
  chains,
  selectedChains,
  onSelectChain,
}: ChainTagsProps) => {

  return <div className="w-[1100px] min-w-[1100px] flex flex-wrap gap-[13px]">
    {
      chains.map((chain, index) => {
        return <Tag
          key={index}
          onClick={onSelectChain}
          id={chain.id}
          name={chain.name}
          isActive={selectedChains.includes(chain.id)}
          className="bg-[#F3F3F3] text-[#282828]"
          activeClassName="bg-[#243AF3] text-[#ffffff]"
        />
      })
    }
  </div>
}

export const LabelTags = ({
  labels,
  selectedLabels,
  onSelectLabel,
}: {
  labels: TagProps[];
  selectedLabels: string[];
  onSelectLabel: (label: string) => void;

}) => {

  return <div className="w-[1100px] min-w-[1100px] flex flex-wrap gap-[13px]">
    {
      labels.map((label, index) => {
        return <Tag
          key={index}
          onClick={onSelectLabel}
          id={label.id}
          name={label.name}
          image={label.icon}
          isActive={selectedLabels.includes(label.id)}
          className="bg-[#e8ebff] text-[#243AF3]"
          activeClassName="bg-[#243AF3] text-[#ffffff]"
        />
      })
    }
  </div>
}

const Separator = ({ text }: {
  text: string
}) => {

  return <div className="flex items-center gap-[14px]">
    <div className="text-[#777A80] text-[14px]">
      {text}
    </div>
    <div className="flex-1 h-[1px] bg-[#E3E6ED]"></div>
  </div>
}



export const TagDisplay = ({
  onClick,
  active,
  id,
  name,
  className
}: {
  onClick?: (chain: string) => void
  active: boolean
  id: string
  name: string,
  className?: string
}) => {
  return <div
    onClick={() => { onClick?.(id) }}
    className={
      classNames('rounded flex py-[6px] px-[10px] items-center gap-[4px] bg-[#fff] text-[14px] leading-[12px] cursor-pointer', {
        'bg-[#263dff] text-[#fff]': active,
      },
        className
      )
    }
  >
    <img src={chainIcon} className="w-[20px] h-[20px]" />
    {name}
  </div>
}

interface TableCardProps {
  tableProject: PoProject
}
const TableCard = ({
  tableProject
}: TableCardProps) => {


  return <Link to={`/v2/explore/tables/${tableProject?.id}/overview?from=explore`}>
    <div className="rounded bg-[#fff] w-[520px] h-[150px] flex gap-[15px] p-[10px] hover:bg-[#fafafa] cursor-pointer">
      <img className="w-[50px] h-[50px] object-contain" src={tableProject.image} />
      <div className="flex flex-col justify-between flex-1">
        <div>
          <div className="mb-[8px] font-semibold text-[16px]">
            {tableProject.name}
          </div>
          <div className="text-[14px] text-[#777A80] line-clamp-3">
            {tableProject.detail}
          </div>
        </div>
        <div className="flex gap-[10px] items-center">
          <div>
            <Tag
              name={tableProject.labels?.[0] || ''}
              image={
                `icon-${(tableProject.labels?.[0] || '').toLowerCase()}`
              }
              id="ethereum"
              className="bg-[#F3F3F3] text-[#282828]"
            />
          </div>
          {tableProject.chains?.length && <div className="pr-[0.5em]">
            <OverlapImages
              className="w-[20px] h-[20px] rounded-full object-contain"
              images={tableProject.chains?.map((chain) => {
                return chain
              }) || []}
            />
          </div>}
          <div className="text-[12px] font-semibold text-[#B7BAC2]">
            {tableProject.count} Tables
          </div>
        </div>
      </div>
    </div>
  </Link>
}

const ExploreTablesV3 = () => {

  const [selectedChains, setSelectedChains] = useState<string[]>([])


  const onSelectChain = (chain: string) => {
    if (selectedChains.includes(chain)) {
      setSelectedChains(selectedChains.filter((c) => c !== chain))
    } else {
      setSelectedChains([chain])
    }
  }
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])


  const onSelectLabel = (label: string) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((c) => c !== label))
    } else {
      setSelectedLabels([label])
    }
  }

  const {
    publicTables,
    // refecth,
    isLoading
  } = useExploreData([ExploreDataEnum.TABLES])

  const filteredTables = publicTables.filter((table) => {
    if (selectedChains.length === 0 && selectedLabels.length === 0) return true

    if (table.chains?.some((chain) => selectedChains.map(e => e.toLocaleLowerCase()).includes(chain.toLowerCase()))) {
      return true
    }

    if (table.labels?.some((label) => selectedLabels.map(e => e.toLocaleLowerCase()).includes(label.toLowerCase()))) {
      return true
    }

    return false
  })

  const renderTableProjects = () => {
    if (isLoading) {
      return <div className="h-[300px] w-full flex justify-center items-center">
        <LoadingIndicator />
      </div>
    }



    return <div className="flex flex-wrap gap-[60px] gap-y-[30px]">
      {
        filteredTables.map((table, index) => {
          return <TableCard key={index} tableProject={table} />
        })
      }
    </div>
  }


  return <div className="min-w-[1240px] w-[1240px] px-[70px] py-[40px] mx-auto">
    {/* Title */}
    <div className="text-left mb-[24px] font-semibold">
      <div className="text-[24px] text-[#2B2F33]leading-[24px]">
        Tables
      </div>
    </div>

    {/* Labels Filter */}
    <div className="mb-[8px]">
      <LabelTags labels={LABELS} selectedLabels={selectedLabels} onSelectLabel={onSelectLabel} />
    </div>
    {/* Chains Filter */}
    <div className="mb-[50px]">
      <ChainTags chains={CHAINS} selectedChains={selectedChains} onSelectChain={onSelectChain} />
    </div>


    {/* Separator */}
    <div className="mb-[20px]">
      <Separator text={`${filteredTables.length} Table Projects`} />

    </div>

    {/* Table Cards */}
    {
      renderTableProjects()
    }


  </div>
}

export default ExploreTablesV3;