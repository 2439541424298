/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Signup } from '../../components/OpenAuth/Signup';
import { Signin } from '../../components/OpenAuth/Signin';
import { Modal } from 'antd';
import Logo from '../../../components/app/Logo';
import { signinGithub } from '../../modules/apis/neo/signinGithub';
import { analytics } from '../../../App';
import { useAIAuth, useOpenAuth } from '../../components/OpenAuth/useOpenAuth';

interface OpenAuthProps {
  type: 'signin' | 'signup';
  authUrl: string;
}

export function OpenAuth({ type, authUrl }: OpenAuthProps) {
  const [auth, { setAuth }] = useAIAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const openAuth = useOpenAuth<string>({
    authUrl,
    onSuccess: async (search) => {
      try {
        const searchParams = new URLSearchParams(search);
        const res = await signinGithub(
          {
            code: searchParams.get('code')!,
            state: searchParams.get('state')!,
          },
          {
            authorize: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        );

        if (res.data?.user) {
          const { user } = res.data;
          setAuth(res.data);

          const analyticsId = user.email?.includes('zettablock')
            ? user.id
            : `${user.id} - ${user.email}`;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          analytics.identify(analyticsId, user as any);
          analytics.track('Auth - Login', {
            type: 'Github',
            isZettaBlockUser: user.email?.includes('zettablock'),
            ...user,
            tenant: user?.tenant || 'community',
            aiWeb: true,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 406) {
            Modal.warn({
              title: 'Unauthorized',
              content: (
                <div>
                  <div className='flex-1 pb-2 space-y-1'>
                    <div>
                      <p className='mb-3'>Hi there!</p>
                      <p className='mb-3'>
                        We&apos;re glad you&apos;re trying to log in to our
                        service. It looks like you&apos;re not currently on our
                        email whitelist.
                      </p>
                      <p className='mb-3'>
                        Don&apos;t worry! Just click the button below to sign up
                        on our website, and we&apos;ll add you to the whitelist
                        soon.
                      </p>
                      <p className='mb-3'>
                        If you have any issues or questions, please don&apos;t
                        hesitate to contact our friendly support team at{' '}
                        <a
                          className='text-primary'
                          href='mailto:team@zettablock.com'
                        >
                          team@zettablock.com
                        </a>
                        !
                      </p>
                      <p className='mb-3'>
                        Happy Building, <br /> ZettaBlock Support Team
                      </p>
                    </div>
                  </div>
                  <div className='modal-action items-center'>
                    <Link to='/aiweb/signup'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          analytics.track(
                            'Auth - Signup - from unauthorized modal'
                          );
                        }}
                      >
                        SIGN UP
                      </button>
                    </Link>
                  </div>
                </div>
              ),
            });
            return;
          }

          const msg =
            error.response?.data.message ||
            'Something went wrong. Please try it again or report it to engineering team.';
          toast.error(msg, {
            autoClose: 2000,
            position: 'top-center',
          });
        }
        setAuth(null);
      }
    },
    onError: () => {
      toast.error('Login Failed. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  useEffect(() => {
    if (auth) {
      if (
        window.history.state?.usr?.back &&
        window.history.state.usr.back !== location.pathname
      ) {
        navigate(window.history.state.usr.back);
      } else {
        navigate('/aiweb/models');
      }
    }
  }, [auth]);

  const renderContent = () => {
    switch (type) {
      case 'signin':
        return <Signin openAuth={openAuth} />;
      case 'signup':
        return <Signup openAuth={openAuth} />;
      default:
        return <Signin openAuth={openAuth} />;
    }
  };

  return (
    <div className='min-h-[calc(100vh-380px)] pt-16'>
      <div className='max-w-[460px] mx-[auto]'>
        <div className='flex justify-center'>
          <Logo />
        </div>
        {renderContent()}
      </div>
    </div>
  );
}
