import { useContext } from 'react';
import { To, UNSAFE_NavigationContext, useLocation } from 'react-router-dom';

let navigateLength = 0;
let navigateCurrent = 0;
export function useNavigator(defaultPathname = '/aiweb/models') {
  const location = useLocation();
  const { navigator } = useContext(UNSAFE_NavigationContext);

  return {
    createHref: navigator.createHref,
    go(delta: number) {
      if (delta > 0) {
        if (delta + navigateCurrent > navigateLength) {
          navigateCurrent = navigateLength;
        } else {
          navigateCurrent += delta;
        }
        navigator.go(delta);
      } else if (delta < 0) {
        if (delta + navigateCurrent < 0) {
          navigateCurrent = 0;
          if (defaultPathname) {
            navigator.replace(defaultPathname);
          } else {
            navigator.go(delta);
          }
        } else {
          navigateCurrent -= delta;
          navigator.go(delta);
        }
      }
    },
    replace: navigator.replace,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    push(to: To, state?: any) {
      if (typeof to === 'string' && to === location.pathname) {
        return;
      }
      navigateLength++;
      navigateCurrent = navigateLength;
      navigator.push(to, {
        ...state,
        back: location.pathname,
      });
    },
  };
}
